<template>
  <div class="a">
    <div class="open">
      <div>是否接案&nbsp;</div>
      <el-switch
        :value="switchVal"
        :active-value="1"
        :inactive-value="2"
        active-color="#1E90FF"
        inactive-color="#B0C4DE"
        @change="onChange"
      />
    </div>
    <div class="all_open">
      <div>接案状态&nbsp;</div>
      <div v-if="switchVal == 1 && userAllOpen == 1">
        <span class="normal"></span>
        <span>正常</span>
      </div>
      <div v-else>
        <span class="abnormal"></span>
        <span>异常</span>
      </div>
    </div>
  </div>
</template>

<script>
import { commonEditUser } from '@/api/common'

export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {
      userinfo: {},
      switchVal: 2,
      userAllOpen: 0,
      localOpen: 0
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    onChange() {
      var param = {}
      if (this.localOpen == 0) {
        this.$notify({
          message: '当前谈案中不可修改',
          type: 'warning'
        })
        return false
      }
      if (this.localOpen == 3) {
        this.$notify({
          message: '因超时/30分钟未拨打电话被系统禁止接案，无法手动打开需进行3次电话沟通将自动打开',
          type: 'warning'
        })
        return false
      }
      if (this.localOpen == 1) {
        param.open = 2
      } else if (this.localOpen == 2) {
        param.open = 1
      }
      commonEditUser(param).then(res => {
        if (res.code == 200) {
          this.$notify({
            message: '接案状态编辑成功',
            type: 'success'
          })
          this.$store.dispatch('user/getInfo')
          this.localOpen = param.open
          this.switchVal = param.open
        }
      })
    },
    async init() {
      await this.$store.dispatch('user/getInfo')
      var userinfo = JSON.parse(localStorage.getItem('loginUserInfo'))
      if (userinfo && userinfo.open) {
        this.userinfo = userinfo
        this.switchVal = userinfo.open == 1 ? 1 : 2
        this.localOpen = userinfo.open
        this.userAllOpen = userinfo.all_open
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.a {
  font-size: 12px;
  position: fixed;
  margin-left: 30px;
  top: 65%;
  right: 3%;
  // display: flex;
  // align-items: center;
  background: rgb(207, 207, 207);
  opacity: 0.9;
  padding: 5px 10px;
  border-radius: 20px;
  z-index: 999;
  .open {
    display: flex;
    align-items: center;
  }
  .all_open {
    display: flex;
    align-items: center;
    margin-top: 3px;
    div {
      display: flex;
      align-items: center;
    }
    .normal,
    .abnormal {
      span {
        display: inline-block;
      }
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      display: flex;
      align-items: center;
    }
    .normal {
      background-color: #67c23a;
    }
    .abnormal {
      background-color: rgb(255, 103, 103);
    }
  }
}
</style>
